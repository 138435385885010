export default {
  heading: 'Cormorant Garamond, serif',
  subheading: 'Limelight, sans-serif',
  body: 'Cormorant Garamond, serif',
  monospace: 'Menlo, monospace',
  display: 'Monoton, serif',
  display2: 'Limelight, cursive',
  googleFonts: [
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Playfair Display:400,500,600,700,800,900',
    'Cormorant Garamond:100,200,300,400,500,600,700,800,900',
    'Monoton',
    'Limelight',
  ],
  customFamilies: ['Din'],
  customUrls: ['https://www.gonation.biz/fonts/din/din.css'],
}
