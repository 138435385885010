export default {
  pageHeroV1: {
    container: {
      height: '45vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1636391437/sites/magic-in-the-dark/artdeco-bg1.png) !important',
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['1.75rem', '', '2.5rem'],
        marginBottom: 1,
        backgroundColor: '#1c1d23',
        padding: '1rem',
        textTransform: 'uppercase',
        color: 'white',
        border: 'solid 6px #a88453',
      },
    },
  },
}
