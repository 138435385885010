export default {
  primary: {
    color: 'light',
    border: 'solid 2px',
    fontSize: '0.9rem',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    fontFamily: 'display2',
    padding: '1rem 3rem',
    borderColor: 'light',
    backgroundColor: '#5b5b48',
    borderRadius: '0px',
    ':hover': {
      backgroundColor: 'dark',
      color: 'light',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    padding: '1rem 0.5rem',
    color: 'secondary',
    border: 'none',
    borderTop: 'solid 1px',
    borderBottom: 'solid 1px',
    borderColor: 'secondary',
    borderRadius: '0px',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '0.85rem',
    transition: 'all ease-in-out 0.8s',
    ':hover': {
      // opacity: '0.4',
      backgroundColor: 'secondary',
      color: 'light',
    },
  },
}
