export default {
  text: 'white',
  primary: '#202020',
  secondary: '#fce324',
  secondaryDark: '#e3b42a',
  background: 'black',
  backgroundSecondary: '#e3e3db',
  light: '#fdfdfd',
  dark: '#202020',
}
