import buttons from './buttons'

export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      padding: ['1rem 0rem', '0.5rem', '1rem', '3rem'],
      zIndex: '1001',
    },
    '.smallNavMenu': {
      flex: 'unset',
      '> div': {
        border: 'none',
        '.navItem': {
          color: 'secondary',
          a: {
            letterSpacing: '0px',
            fontSize: '0.9rem',
          },
        },
      },
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      // flexGrow: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      letterSpacing: '-1px',
      fontSize: '0.9rem',
      ':hover': {
        color: 'inherit',
      },
    },
    '.hamburger': {
      display: ['', '', '', 'none'],
      position: 'static',
      backgroundColor: 'black',
      border: 'solid 1px white',
      padding: '0.25rem',
      height: '35px',
      width: '35px',
      '> div': {
        backgroundColor: 'white',
      },
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '', '100px', ''],
      },
    },
    '.navMenuOpen': {
      backgroundColor: 'primary',
      width: ['90%', '', '40%'],
      justifyContent: ['center', 'center', 'center'],
      padding: '0rem',
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem',
      },
      '.navItem': {
        padding: ['0.75rem', '0.75rem', '1rem', '0.75rem', '0.75rem', '1rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem'],
        },
      },
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)',
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      backgroundColor: '#4d442d',
      paddingBottom: '1rem',
      display: 'none',
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem'],
      },
      margin: '0rem',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'light',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'light',
        },
      },
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: '#c6a34d',
      },
    },
    '.logoLocationContainer': {
      display: 'none',
    },
    '.logo': {
      img: {
        opacity: '1',
        filter: 'brightness(0) invert(1)',
        maxHeight: ['55px', '55px'],
      },
    },
    '.logoScrolled': {
      img: {
        filter: 'brightness(0) invert(1)',
        opacity: '1',
        maxHeight: ['45px', '45px'],
      },
    },
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'black',
    padding: '0rem 0rem',
    paddingBottom: ['0rem', '0rem', '0.5rem'],
    '.socialContainer, .contactDetails-container': {
      display: 'none',
    },
    '.image': {
      filter: 'brightness(0) invert(1)',
      display: 'none',
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary',
      display: 'none',
    },

    '.poweredByText': {
      margin: 'auto',
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'white',
      margin: '0rem',
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'center'],
      margin: '0rem',
      width: '100%',
      backgroundColor: 'primary',
      marginBottom: '0.5rem',
    },
  },

  ctaWidget: {
    display: 'none !important',
    a: {
      backgroundColor: 'secondary',
    },
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    variant: 'customVariants.gradientText',
    width: 'fit-content',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: ['2rem', '2.5rem', '3.5rem', '4rem'],
    textTransform: 'uppercase',
    color: 'white',
    fontWeight: 'bold',
    letterSpacing: '2px',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
  },

  subtitle: {
    color: 'secondary',
    fontWeight: 'bold',
    textTransform: 'initial',
    order: '1',
    fontFamily: 'display2',
    fontSize: ['1rem', '1.5rem', '2rem', '2.5rem'],
    textTransform: 'uppercase',
  },

  text: {
    order: '3',
  },

  gradientText: {
    background: 'linear-gradient(to bottom, #e3b42a 0%, #ffffe8 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },

  sideBySide1: {
    overflow: 'hidden',
    '.content': {
      order: ['', '', '2'],
      width: ['', '', '45%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      '.title': {
        variant: 'customVariants.title',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },
      '.text': {
        variant: 'customVariants.text',
      },
      a: {
        order: '4',
      },
    },
    '.lazyload-wrapper': {
      order: '1',
      width: ['', '', '55%'],
      height: ['', '', '95vh'],
      '::before': {
        background: 'unset',
      },
      img: {
        // height: '100vh',
      },
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  sideBySide2: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        content: ['', '', "''"],
        border: 'solid 3px',
        borderColor: 'white',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%',
      },
      img: {
        // height: '100vh',
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%'],
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%'],
      },
    },

    '.content': {
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },
      '.text': {
        variant: 'customVariants.text',
      },
      a: {
        order: '4',
      },
      '.ctaButton': {
        variant: 'buttons.secondary',
      },
    },
  },

  fadeSections: {
    position: 'relative',
    '::before': {
      content: '""',
      position: 'absolute',
      inset: '0px',
      background:
        'linear-gradient(360deg,rgb(0 0 0 / 0%),rgb(0 0 0 / 12%) 0%,rgb(0 0 0) 50%,rgb(0 0 0) 95%,rgb(0 0 0))',
      height: '10%',
    },
    '::after': {
      content: '""',
      position: 'absolute',
      inset: '0px',
      background:
        'linear-gradient(rgb(0 0 0 / 0%),rgb(0 0 0 / 8%) 0%,rgb(0 0 0 / 58%) 50%,rgb(0 0 0) 95%,rgb(0 0 0))',
      height: '10%',
      margin: 'auto 0rem 0rem',
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    backgroundImage: [
      '',
      '',
      'url(https://res.cloudinary.com/gonation/image/upload/v1636135218/sites/magic-in-the-dark/Asset_1_4x.png)',
    ],
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['100% 90%', '100% 90%', '100% 100%'],
    backgroundPosition: 'center center',
    height: '100vh',
    padding: ['2rem', '', '4rem'],
    justifyContent: 'center',
    '.lazyload-wrapper': {
      width: ['60%', '', '40%'],
      margin: '0rem auto',
      img: {
        padding: ['0rem 2rem', '', '5rem 4rem'],
        objectFit: 'contain',
      },
    },
    '.content': {
      width: ['', '', '60%'],
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: '500px',
    },
    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {},
    '.text': {},
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },

  homepageTestimonials: {
    variant: 'customVariants.fadeSections',
    margin: '3rem 0rem',
    padding: ['4rem 1rem', '', '8rem 1rem 8rem'],
    maxWidth: 'unset',
    backgroundImage:
      'url(https://res.cloudinary.com/gonation/image/upload/v1636391437/sites/magic-in-the-dark/artdeco-bg2.jpg)',
    '.menuContainer': {
      maxWidth: '700px',
      margin: '0 auto 0',
      backgroundColor: 'black',
      border: '6px solid #5b5b48',
      padding: ['0.5rem', '2rem'],
    },

    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem',
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem',
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      margin: '1rem auto 0rem',
      textAlign: 'center',
    },

    '.menuItemName': {
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem'],
      marginBottom: '2rem',
      fontFamily: 'display2',
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'white',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: ['1.25', '1.5'],
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem',
      fontFamily: 'heading',
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto',
    },
  },

  homepageFeaturing: {
    width: '100%',
    padding: ['0rem 1rem', '0rem 2rem', '0rem 3rem', '0rem 5rem'],
    maxWidth: 'unset',

    '.innerMenuContainer': {
      // maxWidth: '1000px',
      margin: '0rem auto',
      position: 'relative',
    },
    '.slick-slider': {
      position: 'static',
      padding: '0rem',
      '.slick-arrow': {
        width: '40px',
        height: '40px',
        backgroundColor: 'secondary',
        padding: '0.5rem',
        color: 'black',
        left: 'unset',
        bottom: 'unset',
        top: '0rem',
        right: '0rem',
        transform: 'unset',
        ':hover': {
          opacity: '0.7',
        },
      },
      '.slick-prev': {
        right: '3rem',
      },
      '.slick-next': {},

      '.slick-dots li.slick-active button:before': {
        color: 'secondary',
      },

      '.slick-dots li button:before': {
        fontSize: '20px',
        color: 'white',
      },
      '.slick-dots': {
        bottom: '25px',
      },
    },
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      width: '80%',
      textAlign: 'left',
      margin: '0rem 0rem 2rem',
      // border: 'none',
    },
    '.menuSectionDescription': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '1.75rem'],
      border: 'none',
      textAlign: 'left',
      color: 'secondary',
    },

    '.menuItemContainerImgActive *': {
      borderRadius: '0px',
      margin: '0rem',
    },

    '.menuItemInnerContainer': {
      display: 'flex',
      flexDirection: ['column', '', 'row'],
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '.itemImageFill': {
        content: '""',
        width: '100%',
        height: '100%',
        background: 'radial-gradient(circle, rgba(0, 0, 0, 0) 0%, black 75%)',
        position: 'absolute',
        left: '0rem',
      },
      '.menuItemImageContainer': {
        width: ['100%', '', '55%'],
        height: ['100vw', '', '50vw'],
        img: {
          // borderRadius: '10px',
        },
      },

      '.menuItemContentContainer': {
        width: ['100%', '', '45%'],
        textAlign: ['right', '', 'left'],
        padding: ['1rem', '', '3rem 1rem 3rem 3rem', '4rem 1rem 4rem 4rem'],
        '.menuItemName': {
          fontSize: ['2rem', '', '3rem'],
          color: 'primaryDark',
          marginBottom: '1.5rem',
        },
        '.menuItemDescription': {
          variant: 'customVariants.subtitle',
          fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.4rem'],
          width: '100%',
        },
      },
    },
  },

  homepageHeroShout: {
    display: 'none',
  },

  homepageShout: {
    '.imageContainer': {
      order: ['2', '', '2'],
    },
    maxHeight: ['', '', '50vh'],
    '.content': {
      order: ['1', '1'],
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '1rem 0rem',
      // display: 'none',
      fontWeight: 'normal',
      border: 'none',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      width: 'fit-content',
      fontSize: '0rem',
      color: 'background',
      '::before': {
        variant: 'customVariants.title',
        content: "'Recent News'", // ! bad coding but could't be bothered to shadow
      },
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem',
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset',
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem',
      color: 'white',
      order: '2',
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem',
      },
    },
  },

  homepageBoxes: {
    marginTop: '4rem',
    padding: '1rem 0rem',
    '.box': {
      position: 'relative',
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1636135218/sites/magic-in-the-dark/Asset_1_4x.png)',
      backgroundSize: '100% 100%',
      // padding: '1rem',
      width: ['calc(100% - 0.5rem * 2)', '', 'calc(50% - 2rem * 2)'],
      // height: '400px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      margin: ['1rem', '', '2rem'],
      padding: ['12rem 1rem', '', '8rem 2rem', '12rem 2rem'],
      flexGrow: '1',
      a: {
        margin: '0rem auto',
      },
      '.image': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        zIndex: '-1',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        filter: 'brightness(0.5)',
      },
      '.title': {
        variant: 'customVariants.subtitle',
        order: 'unset',
        fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.4rem'],
        fontFamily: 'display',
        fontWeight: 'normal',
        color: '#ebd79c',
      },
      '.ctaLink': {
        variant: 'buttons.primary',
      },
    },
  },

  homepagePurchase: {
    variant: 'customVariants.fadeSections',
    '::after': {
      content: 'unset',
    },
    padding: '15vh 1rem',
    '.section': {
      maxWidth: '700px',
      margin: '0 auto 0',
      backgroundColor: 'black',
      border: '6px solid #a6875d',
      padding: ['2rem 1rem', '3rem'],
    },

    '.title': {
      variant: 'customVariants.title',
      order: 'unset',

      border: 'none',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      order: 'unset',
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem'],
      color: '#c8b480',
    },
    '.text': {
      variant: 'customVariants.text',
      order: 'unset',
    },
    '.ctaButton': {
      order: 'unset',
      variant: 'buttons.primary',
    },
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      width: ['100%', '', '40%'],
      img: {
        objectPosition: 'center',
      },
    },
    '.content': {
      width: ['100%', '', '60%'],
    },

    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  // ? ========================
  // ? =====  About BS MOSS page  =====
  // ? ========================

  aboutBSMoss: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      width: ['100%', '', '50%'],
      img: {
        objectPosition: 'center',
      },
    },
    '.content': {
      width: ['100%', '', '50%'],
    },

    '.title': {
      variant: 'customVariants.title',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
    },
    '.text': {
      variant: 'customVariants.text',
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'white',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem',
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem',
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey',
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey',
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center',
      },
    },
  },

  locationMap: {
    order: '2',
    backgroundColor: '#c6a34d',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white',
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0',
    },
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1',
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2',
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none',
    },
    '.box': {
      backgroundColor: '#c5c2c2',
      margin: ['1rem', '', '2rem'],
      width: ['100%', 'calc(50% - 0.5rem * 2)', 'calc(33.3% - 2rem * 2)'],
    },
    '.imageContainer': {
      backgroundColor: 'black',
      img: {
        objectFit: 'contain',
      },
    },
    '.subtitle': {
      color: '#5d574e',
    },
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},
}
